@import "base/mixins";
@import "base/normalize";
@import "base/typography";

:root {
  --container-width: 1300px;
  --container-padding: 20px;
  --vertical-offset: 40px;

  @include breakpoint(md) {
    --container-padding: 6vw;
    --vertical-offset: 5vw;
  }

  @include breakpoint(xl) {
    --container-padding: 75px;
  }
}

body {
  @include font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-width) + (2 * var(--container-padding)));
}

.section-pad {
  padding-top: var(--vertical-offset);
  padding-bottom: var(--vertical-offset);
}

.site-logo {
  display: block;
  width: 80%;
  max-width: 480px;

  @include breakpoint(md) {
    width: 50%;
  }
}

.cans {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: var(--vertical-offset);
}

.can-item {
  margin: 0 4px;
  flex: 1 0 28.3%;

  @include breakpoint(md) {
    flex-basis: 17%;
  }

  @include breakpoint(lg) {
    margin: 0 8px;
    flex-basis: 13%;
  }
}

.text-headline {
  font-size: clamp(44px, 12vw, 180px);
  text-transform: uppercase;
  line-height: 0.75;
}

.text-callout {
  font-size: clamp(18px, 5vw, 45px);
  text-transform: uppercase;
  line-height: 0.9;
  margin-top: 0.75em;
}

.newsletter-block {
  form {
    font-size: clamp(40px, 7vw, 120px);
    text-transform: uppercase;
  }

  input {
    all: unset;
    max-width: 100%;
    line-height: 0.75;
    display: block;
  }

  ::-webkit-input-placeholder {
    color: #d8d8d8;
  }

  ::placeholder{
    color: #d8d8d8;
  }

  .klaviyo_form_actions {
    line-height: 0.75;
  }

  button {
    @include font-primary-medium;
    all: unset;
    cursor: pointer;
    line-height: 0.75;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #d8d8d8;
    }
  }

  .klaviyo_messages {
    line-height: 0.75;
    margin-top: 0.25em;
  }

  .sign-up-success {
    display: none;
    font-size: clamp(40px, 7vw, 120px);
    text-transform: uppercase;
    line-height: 0.75;
  }

  .error_message {
    color: red;
  }
}

.site-footer {
  margin-top: calc(2 * var(--vertical-offset));
  padding: calc(1.5 * var(--vertical-offset)) 0 var(--vertical-offset);
  background: black;
  color: white;
}

.site-footer-bottom {
  @include breakpoint(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site-footer-social-link {
  @include breakpoint(md) {
    order: 99;
  }

  svg {
    width: 24px;
    height: 24px;
    transition: opacity 0.2s ease-in-out;

    @include breakpoint(lg) {
      width: 32px;
      height: 32px;
    }
  }

  a {
    &:hover svg {
      opacity: 0.4;
    }
  }
}

.site-footer-legal {
  font-size: 14px;
  margin-top: 20px;

  @include breakpoint(md) {
    display: flex;
    margin-top: 0;
  }

  @include breakpoint(lg) {
    font-size: 16px;
  }

  div {
    margin-bottom: 16px;

    @include breakpoint(md) {
      margin: 0 24px 0 0;
    }
  }

  a {
    display: inline-block;
    margin-left: 10px;
    transition: opacity 0.2s ease-in-out;

    @include breakpoint(md) {
      margin-left: 24px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      opacity: 0.4;
    }
  }
}
