$breakpoints: (
  'md': 768px,
  'lg': 1024px,
  'xl': 1280px,
  'xxl': 1440px
) !default;

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else if $breakpoint == md-only {
    @media (min-width: map-get($breakpoints, md)) and (max-width: map-get($breakpoints, lg) - 1px) {
      @content;
    }
  }
  @else {
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}
