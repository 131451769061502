@font-face {
  font-family: "founders-grotesk-regular";
  src: url("../fonts/founders-grotesk-web-regular.woff2") format("woff2"),
       url("../fonts/founders-grotesk-web-regular.woff") format("woff");
}

@font-face {
  font-family: "founders-grotesk-medium";
  src: url("../fonts/founders-grotesk-web-medium.woff2") format("woff2"),
       url("../fonts/founders-grotesk-web-medium.woff") format("woff");
}

@mixin font-primary {
  font-family: "founders-grotesk-regular", sans-serif;
}

@mixin font-primary-medium {
  font-family: "founders-grotesk-medium", sans-serif;
}
